@charset "UTF-8";
/* Imports local and external site materials */
@import url("https://fonts.googleapis.com/css?family=Lobster%7CArimo%7CMaven+Pro:400,800,900%7CAlegreya+Sans+SC:900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
@font-face {
  font-family: "Foregen";
  src: url("../fonts/logo/foregen_regular.otf") format("opentype");
}
/* Contains variables, mixins, site-wide styling and typography */
/* Deprecated Color Scheme
$green:     	  #63A04C;
$light-green:   #49A08D;
$red:           #ff533d;
$light-red: 	  #FF7361;
$yellow:    	  #F1D32A;
*/
/* Backgrounds and borders for Cards */
.bg1 {
  background: #509090;
  transition: background 0.7s;
}

.border1 {
  border-color: #509090;
}

.hover1:hover {
  background: #7eb3b3;
  transition: background 0.7s;
}

.bg2 {
  background: #40597b;
  transition: background 0.7s;
}

.border2 {
  border-color: #40597b;
}

.hover2:hover {
  background: #627895;
  transition: background 0.7s;
}

.bg3 {
  background: #ff533d;
  transition: background 0.7s;
}

.border3 {
  border-color: #ff533d;
}

.hover3:hover {
  background: #ff7361;
  transition: background 0.7s;
}

.bg4 {
  background: #555555;
  transition: background 0.7s;
}

.border4 {
  border-color: #555555;
}

.hover4:hover {
  background: #7a7a7a;
  transition: background 0.7s;
}

html,
body {
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  background: #f8f8f8;
  font-family: "Arimo", "Helvetica", sans-serif;
}
html p,
html li,
body p,
body li {
  font-family: "Arimo", "Helvetica", sans-serif;
  letter-spacing: 0.5px;
}
html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Maven Pro", "Arial", sans-serif;
}
html h1 a,
html h2 a,
html h3 a,
html h4 a,
html h5 a,
html h6 a,
body h1 a,
body h2 a,
body h3 a,
body h4 a,
body h5 a,
body h6 a {
  color: #157963;
  font-weight: bold;
}
html h1 a:hover,
html h2 a:hover,
html h3 a:hover,
html h4 a:hover,
html h5 a:hover,
html h6 a:hover,
body h1 a:hover,
body h2 a:hover,
body h3 a:hover,
body h4 a:hover,
body h5 a:hover,
body h6 a:hover {
  text-decoration: underline;
}
html h1 a:visited, html h1 a:link, html h1 a:hover,
html h2 a:visited,
html h2 a:link,
html h2 a:hover,
html h3 a:visited,
html h3 a:link,
html h3 a:hover,
html h4 a:visited,
html h4 a:link,
html h4 a:hover,
html h5 a:visited,
html h5 a:link,
html h5 a:hover,
html h6 a:visited,
html h6 a:link,
html h6 a:hover,
body h1 a:visited,
body h1 a:link,
body h1 a:hover,
body h2 a:visited,
body h2 a:link,
body h2 a:hover,
body h3 a:visited,
body h3 a:link,
body h3 a:hover,
body h4 a:visited,
body h4 a:link,
body h4 a:hover,
body h5 a:visited,
body h5 a:link,
body h5 a:hover,
body h6 a:visited,
body h6 a:link,
body h6 a:hover {
  color: #157963;
}
html h1,
body h1 {
  font-weight: 900;
  font-size: calc(38px + 12 * (100vw - 300px) / 1300);
  line-height: calc(1em + 0.2 * (100vw - 300px) / 1300);
}
html h6,
body h6 {
  text-transform: uppercase;
  margin: auto;
}
html p,
body p {
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  line-height: 1.8rem;
  letter-spacing: 0.8px;
}
html p a,
body p a {
  color: #157963;
  font-weight: bold;
}
html p a:hover,
body p a:hover {
  text-decoration: underline;
}
html p a:visited, html p a:link, html p a:hover,
body p a:visited,
body p a:link,
body p a:hover {
  color: #157963;
}
html li a,
body li a {
  color: #157963;
  font-weight: bold;
}
html li a:hover,
body li a:hover {
  text-decoration: underline;
}
html li a:visited, html li a:link, html li a:hover,
body li a:visited,
body li a:link,
body li a:hover {
  color: #157963;
}
html a:link,
body a:link {
  text-decoration: none;
}
html ul,
body ul {
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  list-style: none inside;
  padding: 0;
}
html ul a:link,
html ul a:visited,
html ul a:hover,
body ul a:link,
body ul a:visited,
body ul a:hover {
  color: #f8f8f8;
}
html dl,
body dl {
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  line-height: 1.8rem;
}

/* Contains individual design elements */
/**

The finest custom page for Peter Fein

*/
.page {
  min-height: calc(100vh - 4rem);
}
.page .hero-page {
  background-image: linear-gradient(#7a7a7aaa, #2b2b2bbb), url("../images/slithering.jpg");
  border-bottom: solid 6px #303030;
}
.page .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.page .bg,
.page .project {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page .an-image {
  height: 100px;
  width: auto;
  align-self: flex-end;
  margin-right: 5%;
  border-radius: 100px;
}
.page .this {
  width: 100%;
  text-align: center;
}
.page .that {
  width: 100%;
  text-align: right;
}
.page a:link,
.page a:visited,
.page a:hover,
.page a:active {
  color: #157963;
  font-weight: bolder;
}

/* the current background-picture tag functionality -- this could really do for a refresh to save you some labor! */
.author.author-benji {
  background: linear-gradient(#000000aa, #000000aa), url(../images/benji-web-stuff.jpg) center/cover no-repeat;
}
.author.author-benji:hover {
  background: linear-gradient(#00000088, #00000088), url(../images/benji-web-stuff.jpg) center/cover no-repeat;
}

/* Authors Page // Tags Page */
.authors,
.author {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.authors {
  align-items: center;
}

.author {
  background: linear-gradient(#000000aa, #000000aa), url(../images/slithering.jpg) center/cover no-repeat;
  align-items: flex-end;
}
.author a:link,
.author a:hover,
.author a:visited,
.author a:active {
  color: white;
  text-decoration: none;
  margin-top: auto;
  text-align: center;
}

/* Index Page */
.index {
  min-height: calc(100vh - 4rem);
}
.index .hero-main {
  background-image: linear-gradient(to right, #303030, transparent), url("../images/heroes/office.jpg");
  background-position: bottom;
  background-size: cover;
  display: flex;
  justify-content: left;
  flex-wrap: wrap-reverse;
  border-bottom: solid 8px #303030;
}
.index .hero-main .hero-text {
  padding: 3rem 5% 0;
  margin: 0 auto;
}
.index .hero-main .hero-text a:link {
  color: #ff533d;
}
.index .hero-main .hero-text a:visited {
  color: #ff533d;
}
.index .hero-main .hero-text .text-image-wrapper {
  display: flex;
  align-content: flex-start;
}
.index .hero-main .hero-text .hero-title {
  color: #509090;
  font-family: "Alegreya Sans SC", "Maven Pro", sans-serif;
  font-size: calc(50px + 50 * (150vw - 300px) / 1300);
  margin: 0 0 1rem;
  vertical-align: middle;
}
.index .hero-main .hero-text .hero-subtitle {
  font-family: "Lobster", cursive;
  font-size: calc(20px + 20 * (200vw - 300px) / 1300);
  letter-spacing: 0.6px;
  font-weight: 500;
  margin-bottom: 0;
}
.index .hero-main .hero-text .hero-img-container {
  margin-left: 3rem;
  padding-top: 0.5rem;
  align-self: center;
}
@media (max-width: 750px) {
  .index .hero-main .hero-text .hero-img-container {
    display: none;
  }
}
.index .hero-main .hero-text .hero-img {
  width: 45%;
}
.index .hero-main .hero-text .hero-p {
  margin-top: 2rem;
  font-size: 1.5rem;
  max-width: 700px;
}
.index .buildit {
  text-align: center;
  align-self: center;
  min-width: 200px;
  height: 90px;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0px 15px 15px -10px #7a7a7a;
  color: #f8f8f8;
  padding: 1rem 0;
}
.index .buildit a,
.index .buildit a:link,
.index .buildit a:hover,
.index .buildit a:visited {
  color: #f8f8f8;
  text-decoration: none;
}
.index .buildit-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.index .buildit-grid {
  width: 450px;
  max-width: 90%;
  padding: 1rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  justify-items: center;
  align-items: center;
  align-content: center;
}
.index .buildit-grid a {
  color: #000;
  width: 180px;
  height: 80px;
  text-align: center;
  padding: 0.5rem;
  margin: 0.5rem 0;
  list-style: inside none;
}
.index .buildit-grid a:hover {
  background: lightgray;
  transition: ease-out 0.5s;
}
.index .buildit-1 {
  border: solid 1px #509090;
}
.index .buildit-2 {
  border: solid 1px #40597b;
}
.index .buildit-3 {
  border: solid 1px #ff533d;
}
.index .link-container {
  margin: 3rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 500px;
}
.index .link-container .top-left,
.index .link-container .top-right,
.index .link-container .bottom-right,
.index .link-container .bottom-left {
  width: 248px;
  display: block;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  text-decoration: none;
}
.index .link-container .top-left,
.index .link-container .top-right {
  border-color: #509090;
  border-bottom: solid 2px #303030;
}
.index .link-container .bottom-left,
.index .link-container .bottom-right {
  border-color: #40597b;
  border-top: solid 2px #40597b;
}
.index .link-container .top-left,
.index .link-container .bottom-left {
  border-right: solid 2px #509090;
}
.index .link-container .top-right,
.index .link-container .bottom-right {
  border-left: solid 2px #509090;
}
@media (max-width: 520px) {
  .index .link-container .top-right,
.index .link-container .bottom-right {
    border: 2px solid #509090;
    margin-top: 0.5rem;
  }
  .index .link-container .top-left,
.index .link-container .bottom-left {
    border: 2px solid #40597b;
    margin-top: 0.5rem;
  }
}

/* Large Contact Button */
#large-contact-button {
  color: #157963;
  box-shadow: 0px 15px 15px -10px #7a7a7a;
  transition: 0.5s;
  background: none;
  border: 2px solid;
  margin-bottom: 3rem;
  padding: 1rem 4rem;
  cursor: pointer;
}
#large-contact-button:hover, #large-contact-button:focus {
  border-color: #509090;
  background-color: #509090;
  color: #fff;
}
#large-contact-button h2 {
  pointer-events: none;
}

.schedule-call-button {
  background-color: white !important;
  font-size: 0.75rem !important;
  padding: 0.75rem !important;
  border: none !important;
}

.tg-wrap {
  flex-direction: column;
}

.homepage-cta-text {
  text-align: center;
  line-height: 3rem;
  color: white;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  font-weight: 800;
  max-width: 35rem;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: normal;
}
.homepage-cta-text strong {
  font-weight: bolder;
}

.homepage-cta-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 1280px;
  margin: 0 auto;
  margin-top: 2rem;
}

.cta-text-wrapper {
  flex: 2 1 auto;
  display: flex;
  margin-left: 2rem;
  justify-content: center;
  min-width: fit-content;
  max-width: 47.5rem;
  background: url(../images/pete_fein_headshot.jpg) center/contain no-repeat;
  height: 400px;
  align-items: center;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 900px) {
  .cta-text-wrapper {
    margin-left: 0;
  }
}

.cta-text-wrapper:hover {
  background: linear-gradient(rgba(21, 121, 99, 0.3), rgba(21, 121, 99, 0.3)), url(../images/pete_fein_headshot.jpg) center/contain no-repeat !important;
}

.homepage-cta-block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  color: white;
  padding: 10px;
  flex: 1 1 auto;
  background: #7a7a7a;
  min-width: 348px;
  max-width: 348px;
  border-radius: 5px;
  height: 385px;
  font-size: 1.2rem;
}

.training-text-wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../images/heroes/rolodex.jpg) center/cover no-repeat;
}

.training-ul li {
  padding: 0.4rem;
}

/* Homepage Training table */
.event-table {
  display: table;
  margin: 2rem 3.5rem;
  border-collapse: collapse;
  margin-bottom: 10px;
  border-collapse: separate;
  border-color: grey;
}
@media screen and (max-width: 600px) {
  .event-table {
    margin: 0 2.5rem;
  }
}

.event-table-row {
  display: table-row;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background: #fff;
  cursor: pointer;
}

.event-table-row:nth-child(odd) {
  background-color: rgba(255, 208, 196, 0.8);
}

.event-table-cell {
  display: table-cell;
  padding: 5px;
}

.date-column {
  min-width: 14rem;
  text-align: center;
}

.detail-column {
  padding: 0rem 2rem;
}
.detail-column h4 {
  color: #509090;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.detail-column p {
  line-height: 26px;
  max-width: 35rem;
}

.table-row-link {
  display: contents;
  color: black !important;
}

@media screen and (max-width: 660px) {
  .event-table {
    margin: 0 0.5rem;
  }

  .event-table,
.event-table-row,
.event-table-cell {
    display: block;
    margin-bottom: 0;
    padding: 10px;
  }

  .date-column {
    display: flex;
    justify-content: space-around;
  }
}
/* About Page */
.about {
  min-height: calc(100vh - 4rem);
}
.about .hero-about {
  background-position: top;
  background-image: linear-gradient(#62789566, #62789566), url("../images/heroes/zen_laughing_flip.jpg");
  border-bottom: solid 6px #303030;
}
.about .wrapper {
  flex-direction: column;
  align-items: center;
}

.about-link-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-link {
  color: #40597b !important;
  font-size: 1.75rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
}

/* Open-Source Page / Services Page */
.projects {
  min-height: calc(100vh - 4rem);
}
.projects .hero-projects {
  background-position: bottom;
  background-image: linear-gradient(#ff736166, #ff736166), url("../images/heroes/code.jpg");
  border-bottom: solid 6px #303030;
}
.projects .hero-services {
  background-position: center;
  background-image: linear-gradient(#7a7a7aaa, #7a7a7aaa), url("../images/heroes/files.jpg");
  border-bottom: solid 6px #303030;
}

/* Solutions Page */
.solutions {
  min-height: calc(100vh - 4rem);
}
.solutions .hero-solutions {
  background-position: bottom;
  background-image: linear-gradient(#7eb3b366, #7eb3b366), url("../images/heroes/blackboard.jpg");
  border-bottom: solid 6px #303030;
}
.solutions .wrapper {
  align-items: center;
}
.solutions .wrapper-right {
  align-items: center;
}
.solutions .fancy-list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  background: #f8f8f8;
  margin: 5%;
  min-width: 300px;
  max-width: 100%;
  border: solid 1px lightgray;
}
.solutions .fancy-list li {
  padding: 1.3rem;
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  line-height: calc(1.3em + 0.3 * (100vw - 300px) / 1300);
  color: #7a7a7a;
  border-bottom: solid 1px lightgray;
  text-align: center;
}
.solutions .fancy-list .last-fl-item {
  border: none;
}
.solutions .solution {
  max-width: 450px;
  color: #f8f8f8;
  margin: 0rem 2rem 1rem;
  padding: 0;
  text-align: center;
}
.solutions .solution h3 {
  margin-top: 1rem;
}

.contact {
  min-height: calc(100vh - 4rem);
}
.contact .hero-contact {
  background-position: center;
  background-image: linear-gradient(#7a7a7aaa, #7a7a7aaa), url("../images/heroes/rolodex.jpg");
  border-bottom: solid 6px #303030;
}
.contact .contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0 1rem;
  align-items: center;
}
.contact .contact-form {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-width: 400px;
  margin: 0 3rem;
}
.contact .contact-form .post {
  margin: 7px;
  border: 1px solid;
  padding: 7px;
  background: white;
  outline: none;
}
.contact .contact-form .submit {
  cursor: pointer;
}
.contact .contact-form .submit:hover {
  background: #7a7a7aaa;
  transition: ease-out 0.3s;
}
.contact .contact-info {
  margin: 0rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
}
.contact .contact-info .fa {
  margin: 0px 10px 5px -10px;
}
.contact .contact-info h6 {
  margin: 0.5rem 0;
  font-size: 16px;
}
.contact .contact-info .contact-us {
  margin-bottom: 1rem;
}

.contact-page-form {
  padding-top: 1.3rem;
  text-align: center;
}
.contact-page-form .hero-contact {
  background-position: center;
  background-image: linear-gradient(#7a7a7aaa, #7a7a7aaa), url("../images/heroes/rolodex.jpg");
  border-bottom: solid 6px #303030;
}
.contact-page-form .contact-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0 3rem;
  align-items: center;
}
.contact-page-form .contact-form {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-width: 400px;
  margin: 0 3rem;
}
.contact-page-form .contact-form .post {
  margin: 7px;
  border: 1px solid;
  padding: 7px;
  background: white;
  outline: none;
}
.contact-page-form .contact-form .submit {
  cursor: pointer;
}
.contact-page-form .contact-form .submit:hover {
  background: #7a7a7aaa;
  transition: ease-out 0.3s;
}

/* New Contact form! */
#contact {
  text-align: center;
  min-height: unset !important;
  max-width: 850px;
  z-index: 10;
  max-height: 0;
  transition: ease max-height 0.2s, ease opacity 0.2s;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #f4f4f4;
  transform: translate(-50%, -50%);
  width: 70%;
  overflow: hidden;
}
#contact.active {
  max-height: 800px;
  opacity: 1;
}
@media (max-width: 550px) {
  #contact {
    width: 85% !important;
  }
}

.contact-wrapper {
  margin: 2rem 0 3rem !important;
}

.ex {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  padding: 10px;
  cursor: pointer;
}
.ex span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  transform: translate(-50%, -50%) rotate(45deg);
  background: black;
}
.ex span:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.contact-btn {
  cursor: pointer !important;
}

footer {
  position: absolute;
  z-index: 99;
}

.dimmer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9;
  pointer-events: none;
  transition: ease all 0.2s;
}
.dimmer.active {
  background-color: #0006;
}

body.locked {
  overflow: hidden !important;
  height: 100% !important;
}

/* Blog Posts */
.blog-post {
  min-height: calc(100vh - 4rem);
}
.blog-post .blog-post-hero {
  background-image: linear-gradient(#7a7a7aaa, #2b2b2bbb), url("../images/slithering.jpg");
  color: #f8f8f8;
  border-bottom: solid 6px #303030;
  padding-bottom: 3%;
}
.blog-post .blog-post-hero .site-wrapper-medium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.blog-post .blog-post-hero a.date {
  margin-top: calc(70px - 5%);
}
.blog-post .blog-post-hero .title {
  margin: 90px 0 35px;
}
.blog-post .blog-post-hero a.author-meta {
  margin: 0;
  color: #f8f8f8;
}
.blog-post .blog-post-hero .tag {
  margin-bottom: 50px;
}
.blog-post .site-wrapper-big {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 500px;
}
.blog-post .blog-post-body {
  width: 90%;
  max-width: 720px;
  margin: 0 auto;
}
.blog-post .blog-post-body h1,
.blog-post .blog-post-body h2,
.blog-post .blog-post-body h3,
.blog-post .blog-post-body h4,
.blog-post .blog-post-body h5,
.blog-post .blog-post-body h6 {
  margin: 2rem auto 1rem;
  font-weight: bold;
  font-family: "Maven Pro", "Arial", sans-serif;
}
.blog-post .blog-post-body h2 {
  font-size: calc(26px + 10 * (100vw - 300px) / 1300);
}
.blog-post .blog-post-body h3 {
  font-size: calc(24px + 8 * (100vw - 300px) / 1300);
}
.blog-post .blog-post-body p {
  margin: 0 auto 1.6em;
}
.blog-post .blog-post-body ul {
  margin: 1rem auto;
  list-style: circle inside;
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
}
.blog-post .blog-post-body ul li {
  padding-left: 40px;
  padding-bottom: 0.5rem;
}
.blog-post .blog-post-body ul li::marker {
  color: #40597b;
  content: "⯈ ";
}
.blog-post .blog-post-body ol {
  margin: 0 1rem 2rem;
  list-style: decimal inside;
  padding-left: 1rem;
}
.blog-post .blog-post-body blockquote {
  font-family: "Maven Pro", "Arial", sans-serif;
  border-left: 3px solid #7eb3b3;
  background-color: #7a7a7a;
  color: white;
  max-width: 75%;
  padding: 1rem 1.5rem;
  margin: 0 0 1.6em 20px;
}
.blog-post .blog-post-body blockquote a {
  color: #303030;
  font-weight: bold;
}
.blog-post .blog-post-body blockquote a:hover {
  text-decoration: underline;
}
.blog-post .blog-post-body blockquote a:visited, .blog-post .blog-post-body blockquote a:link, .blog-post .blog-post-body blockquote a:hover {
  color: #303030;
}
.blog-post .blog-post-body blockquote p {
  color: white;
}
.blog-post .blog-post-body blockquote p:last-of-type {
  margin: 0;
}
.blog-post .blog-post-body pre {
  background: #2b2b2b;
  color: white;
  border-left: 3px solid #509090;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  line-height: calc(1.3em + 0.3 * (100vw - 300px) / 1300);
  max-width: 75%;
  overflow: auto;
  padding: 1rem 1.5rem;
  word-wrap: break-word;
  margin: 0 0 1.6em 10px;
}
.blog-post .blog-post-body dl {
  margin: -1.6em auto 1.6em;
}
.blog-post .blog-post-body dt {
  font-weight: bold;
  padding: 10px 0 5px 20px;
}
.blog-post .blog-post-body img {
  margin: 0 30%;
  width: 40%;
}
.blog-post .blog-post-body .hljs-comment,
.blog-post .blog-post-body .hljs-quote {
  color: #7eb3b3;
}
.blog-post .blog-post-body .hljs-variable,
.blog-post .blog-post-body .hljs-template-variable,
.blog-post .blog-post-body .hljs-tag,
.blog-post .blog-post-body .hljs-name,
.blog-post .blog-post-body .hljs-selector-id,
.blog-post .blog-post-body .hljs-selector-class,
.blog-post .blog-post-body .hljs-regexp,
.blog-post .blog-post-body .hljs-deletion {
  color: #ffa07a;
}
.blog-post .blog-post-body .hljs-number,
.blog-post .blog-post-body .hljs-built_in,
.blog-post .blog-post-body .hljs-builtin-name,
.blog-post .blog-post-body .hljs-literal,
.blog-post .blog-post-body .hljs-type,
.blog-post .blog-post-body .hljs-params,
.blog-post .blog-post-body .hljs-meta,
.blog-post .blog-post-body .hljs-link {
  color: #ff7361;
}
.blog-post .blog-post-body .hljs-attribute {
  color: #f5dd58;
}
.blog-post .blog-post-body .hljs-string,
.blog-post .blog-post-body .hljs-symbol,
.blog-post .blog-post-body .hljs-bullet,
.blog-post .blog-post-body .hljs-addition {
  color: #509090;
}
.blog-post .blog-post-body .hljs-title,
.blog-post .blog-post-body .hljs-section {
  color: #00e0e0;
}
.blog-post .blog-post-body .hljs-keyword,
.blog-post .blog-post-body .hljs-selector-tag {
  color: #627895;
}
.blog-post .blog-post-body .hljs {
  display: block;
  overflow-x: auto;
  background: #2b2b2b;
  color: #f8f8f2;
  padding: 0.5em;
}
.blog-post .blog-post-body .hljs-emphasis {
  font-style: italic;
}
.blog-post .blog-post-body .hljs-strong {
  font-weight: bold;
}
@media screen and (-ms-high-contrast: active) {
  .blog-post .blog-post-body .hljs-addition,
.blog-post .blog-post-body .hljs-attribute,
.blog-post .blog-post-body .hljs-built_in,
.blog-post .blog-post-body .hljs-builtin-name,
.blog-post .blog-post-body .hljs-bullet,
.blog-post .blog-post-body .hljs-comment,
.blog-post .blog-post-body .hljs-link,
.blog-post .blog-post-body .hljs-literal,
.blog-post .blog-post-body .hljs-meta,
.blog-post .blog-post-body .hljs-number,
.blog-post .blog-post-body .hljs-params,
.blog-post .blog-post-body .hljs-string,
.blog-post .blog-post-body .hljs-symbol,
.blog-post .blog-post-body .hljs-type,
.blog-post .blog-post-body .hljs-quote {
    color: highlight;
  }
  .blog-post .blog-post-body .hljs-keyword,
.blog-post .blog-post-body .hljs-selector-tag {
    font-weight: bold;
  }
}

.hero-blog-index {
  background-color: #000;
  border-bottom: solid 6px #303030;
}
.hero-blog-index .wrapper {
  justify-content: flex-start;
  max-width: calc(90% + 1rem);
}
.hero-blog-index .hamburger {
  min-width: 40px;
  position: relative;
  border-radius: 20px;
  border-bottom-left-radius: unset;
  background-color: #ff533d;
  transition: background-color ease 0.1s 0.4s;
}
.hero-blog-index .hamburger .fa {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  height: 50%;
  width: 50%;
  opacity: 1;
  transition: ease opacity 0.1s 0.5s;
}
.hero-blog-index .hamburger:hover {
  animation-delay: 10000000s;
  background-color: black;
  transition-delay: unset;
  transition-duration: 0.2s;
}
.hero-blog-index .hamburger:hover .fa {
  opacity: 0;
  transition-delay: unset;
  transition-duration: 0.1s;
}
.hero-blog-index .hamburger:hover .cards {
  max-width: 600px;
}
.hero-blog-index .cards {
  display: flex;
  max-width: 0;
  overflow: hidden;
  transition: ease-in max-width 0.5s;
}
.hero-blog-index .card {
  padding: 10px;
  color: white;
  border-radius: 20px;
  border-bottom-left-radius: unset;
  border-bottom-left-radius: unset;
  margin-right: 10px;
}
.hero-blog-index .card:last-of-type {
  margin-right: unset;
}
.hero-blog-index .card:nth-child(n) {
  background-color: #40597b;
}
.hero-blog-index .card:nth-child(n):hover {
  background-color: #627895;
}
.hero-blog-index .card:nth-child(2n) {
  background-color: #ff533d;
}
.hero-blog-index .card:nth-child(2n):hover {
  background-color: #ff7361;
}
.hero-blog-index .card:nth-child(3n) {
  background-color: #509090;
}
.hero-blog-index .card:nth-child(3n):hover {
  background-color: #7eb3b3;
}

.blog-index {
  min-height: calc(100vh - 4rem);
}
.blog-index .project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 350px;
  color: white;
  position: relative;
}
.blog-index .project:nth-child(2n-1) {
  background: linear-gradient(#2b2b2bbb, #2b2b2bbb), url("../images/heroes/code.jpg") center/cover, no-repeat;
}
.blog-index .project:nth-child(2n) {
  background: linear-gradient(#7eb3b366, #2b2b2bbb), url("../images/slithering.jpg") center/cover no-repeat;
}
.blog-index .project .metadata {
  position: absolute;
  bottom: 2rem;
}

/* detail page table */
.tg-wrap {
  flex-direction: column;
}

.detail-page-table {
  display: table;
  margin: 0 3.5rem;
  border-collapse: collapse;
  margin-bottom: 10px;
  border-collapse: separate;
  border-color: grey;
  min-width: -webkit-fill-available;
}
@media screen and (max-width: 600px) {
  .detail-page-table {
    margin: 0 2.5rem;
  }
}

.detail-page-table-row {
  display: table-row;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background: #fff;
}

.detail-page-table-row:nth-child(odd) {
  background-color: rgba(255, 208, 196, 0.8);
}

.detail-page-table-cell {
  display: table-cell;
  padding: 5px;
}
.detail-page-table-cell p {
  margin: 0.2rem 0.5rem !important;
}

.detail-page-date-column {
  min-width: 14rem;
  text-align: center;
}

.detail-page-detail-column {
  text-align: center;
  min-width: 14rem;
  padding: 0rem 2rem;
  vertical-align: middle;
}

.detail-page-register-column {
  min-width: 10rem;
  text-align: center;
  vertical-align: middle;
}

@media screen and (max-width: 660px) {
  .detail-page-table,
.detail-page-table-row,
.detail-page-table-cell {
    display: block;
    margin: 0;
  }
}
.detail-page-course-detail-description {
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
  margin-top: 2rem;
}

.register-button,
.tito-widget-button {
  background-color: #509090 !important;
  color: white;
  transition: 0.5s;
  background: none;
  border: 1px solid #509090;
  padding: 0.3rem 1.7rem;
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
  cursor: pointer;
}
.register-button h2,
.tito-widget-button h2 {
  pointer-events: none;
}

/* Contains site-wide block elements and designs */
.white-link {
  color: white !important;
}

.margined {
  max-width: 90%;
  width: 1280px;
  margin: 0 auto;
}

.padded {
  max-width: 100%;
}
@media (min-width: 1280px) {
  .padded {
    padding-left: calc(50vw - 640px);
    padding-right: calc(50vw - 640px);
  }
}

.no-pd {
  padding: 0;
}

.no-mg {
  margin: 0;
}

.mw-450 {
  max-width: 450px;
}

.homepage-h2 {
  width: 100%;
  padding: 1rem 0 0.5rem;
  margin: 0 1rem;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  font-weight: 800;
}

/* Heroes */
.hero {
  position: relative;
  margin: 0 auto;
  color: #f8f8f8;
  margin-bottom: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 7%;
  padding-bottom: 1%;
}
.hero h2 {
  margin: 1rem auto;
}

.hero-small {
  background: center/100%;
  padding: 7% 30% 5% 4rem;
  margin: 2rem 0;
  width: 50%;
  transition: ease all 0.2s;
}
.hero-small h2,
.hero-small p {
  max-width: 300px;
}
.hero-small:hover {
  transition-duration: 0.5s;
}

.hero-small-blog {
  background: center/100%;
  padding: 7% 30% 5% 4rem;
  margin: 2rem 0;
  width: 50%;
  transition: ease all 0.2s;
  padding: 2rem;
  cursor: pointer;
  width: 86%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 4rem 0;
}
.hero-small-blog h2,
.hero-small-blog p {
  max-width: 300px;
}
.hero-small-blog:hover {
  transition-duration: 0.5s;
}
.hero-small-blog h2 {
  margin: 0;
}
@media (max-width: 500px) {
  .hero-small-blog {
    padding-right: 20%;
    width: 60%;
  }
}
@media (max-width: 800px) {
  .hero-small-blog {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.hero-small-blog .tagline {
  margin-bottom: 0;
}
.hero-small-blog:hover {
  background-image: linear-gradient(black, black) !important;
}

.testimonials-block {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.testimonials-block h2 {
  font-family: "Maven Pro", "Arial", sans-serif;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  text-align: center;
  font-weight: 800;
}
.testimonials-block .blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.testimonials-block .block {
  max-width: 35rem;
}
.testimonials-block.two .block, .testimonials-block.four .block {
  width: 47%;
  margin: 0 10px;
  margin-bottom: 2%;
}
.testimonials-block.two .block:last-of-type, .testimonials-block.two .block:nth-child(2n), .testimonials-block.four .block:last-of-type, .testimonials-block.four .block:nth-child(2n) {
  margin-right: unset;
}
.testimonials-block .block {
  transition: ease all 0.5s;
  border-width: 5px;
  box-shadow: 0 15px 15px -10px #7a7a7a;
  border-style: solid;
}
.testimonials-block .quote {
  font-size: 8rem;
  font-family: "Arimo", "Helvetica", sans-serif;
  text-align: center;
  display: block;
  padding-top: 15px;
  height: 60px;
  margin-bottom: 20px;
}
.testimonials-block .testimonial-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.testimonials-block .testimonial-author-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.testimonials-block .testimonial-text {
  display: -webkit-box;
  height: 8.7rem;
  font-size: 1rem;
  text-align: left !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px 0px;
  line-height: calc(1.3em + 0.3 * (100vw - 300px) / 1300);
}
.testimonials-block .testimonial-text,
.testimonials-block .testimonial-author {
  text-align: center;
}
.testimonials-block .split {
  margin-left: auto;
  margin-right: auto;
}
.testimonials-block .circle-img {
  aspect-ratio: 1/1;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 8px;
}
.testimonials-block .circle-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonials-block .testimonial-author {
  font-weight: 600;
  letter-spacing: 0.8px;
  font-size: 1rem;
}
.testimonials-block .testimonial-author-subtext {
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 0.8rem;
  text-align: center;
}
.testimonials-block .test-pad {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

@media (max-width: 925px) {
  .testimonial-text {
    height: 9.2rem;
    font-size: 0.85rem !important;
  }

  .testimonial-author-subtext {
    font-size: 0.75rem !important;
  }

  .testimonials-block {
    width: 450px !important;
  }
  .testimonials-block .blocks .block {
    width: 100%;
    margin-right: unset;
  }
}
#contact-hero {
  padding: 0;
  width: 80%;
  max-width: 80%;
}
@media (max-width: 600px) {
  #contact-hero {
    width: 90%;
    max-width: 90%;
  }
}

.title {
  text-align: center;
}

.title-ul {
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 0;
  font-weight: 900;
  color: #f8f8f8;
}

.page-title {
  padding-top: 2rem;
  text-align: center;
  text-transform: uppercase;
  margin: 1em auto calc(1em + 4%);
}

.workshop-title {
  font-size: 1.75rem;
  line-height: 2.5rem;
  max-width: 90%;
  align-self: center;
}

.contact-form-title {
  margin-bottom: 2rem;
}

.logo {
  padding: 15px 20px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo .logo-text {
  margin: 12px -6px 0;
  color: #f8f8f8;
  font-weight: 600;
  letter-spacing: 1.1px;
  font-family: "Alegreya Sans SC", "Maven Pro", sans-serif;
}
.logo .logo-text:hover {
  text-decoration: none;
}
.logo .logo-img {
  height: 30px;
}

/* Under-hero paragraph about page information */
.explainer-flex {
  max-width: 90%;
  margin: 0 auto;
}
.explainer-flex h2 {
  padding: 1rem 0 0.5rem;
  margin: 0 1rem;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  font-weight: 800;
}
.explainer-flex p {
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
  color: #303030;
  max-width: 900px;
  margin: 0 1rem 3rem;
}

.workshop-text {
  max-width: 90%;
  width: 45%;
  margin: 0 auto;
  margin-top: 1.5rem;
}
.workshop-text h2 {
  padding: 1rem 0 0.5rem;
  margin: 0 1rem;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  font-weight: 800;
}
.workshop-text p {
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
  color: #303030;
  max-width: 900px;
  margin: 0 1rem 3rem;
}
@media (max-width: 1114px) {
  .workshop-text {
    width: 90%;
  }
}

.header-highlighted-text {
  background-color: #509090;
  padding: 5px;
  color: white;
}

.site-wrapper-medium {
  width: 720px;
  max-width: 90%;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 1280px;
  margin: 0 auto;
}

@media (min-width: 1114px) {
  .workshop-wrapper {
    margin-bottom: 3rem;
  }
}

.wrapper-right {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Images */
.img-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.img-box h2 {
  padding: 1rem 0 0.5rem;
  margin: 0 1rem;
  font-size: calc(26px + 4 * (100vw - 300px) / 980);
  font-weight: 800;
}
.img-box p {
  font-size: calc(17px + 3 * (100vw - 300px) / 980);
  color: #303030;
  max-width: 900px;
  margin: 0 1rem 3rem;
  max-width: 650px;
}

.img {
  width: 160px;
  height: 200px;
  margin: 0 0 2rem 0;
  box-shadow: 4px 3px 10px -3px black;
}

.img-lg {
  max-width: 350px;
  margin: 1rem;
  box-shadow: -4px 3px 10px -3px black;
}

/* Add some space between things with this */
.splitter {
  margin: 5rem;
}

/* If you need a break from a Flex row, here's a cheap way to do it */
.empty {
  width: 100%;
}

/* To-top Button */
.to_top {
  position: fixed;
  bottom: 80px;
  right: 60px;
  height: 40px;
  background: #303030;
  border: #f8f8f8 1px solid;
  cursor: pointer;
  color: #f8f8f8;
  text-align: center;
  border-radius: 4px;
  width: 0;
  height: 0;
  opacity: 0;
  transition: 400ms;
}
.to_top:hover {
  background: #f8f8f8;
  color: #509090;
  border-color: #509090;
}

.to_top-show {
  bottom: 60px;
  right: 40px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  opacity: 1;
  transition: 400ms;
}

.rss-box {
  position: relative;
  width: 1280px;
}

.rss {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #f8f8f8;
}

.footer {
  background: #303030;
  color: #f8f8f8;
  width: 100%;
  height: auto;
  margin-top: 1rem;
  padding-top: 1rem;
}
.footer a:hover,
.footer a:link,
.footer a:visited {
  color: #f8f8f8;
}
.footer .padded {
  text-align: center;
}
.footer .ft-link {
  margin-right: 0.5rem;
  color: #f8f8f8 !important;
  font-weight: normal;
}
.footer .container {
  display: inline-block;
}
.footer .col {
  width: auto;
  height: auto;
  float: left;
  text-align: start;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 1.25rem 1.25rem 1.25rem;
}
.footer .col h2 {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 12px;
  padding: 1.25rem 0px 0.3125rem 0px;
  color: #509090;
  font-weight: normal;
  text-transform: uppercase;
}
.footer .social h2 {
  margin-bottom: 1rem;
}
.footer .footer .c .col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer .contact-btn {
  color: #509090 !important;
}
.footer .col ul li {
  padding: 0.3125rem 0px 0.3125rem 0px;
}
.footer .social ul li {
  display: inline-block;
  padding-right: 0.3125rem !important;
}
.footer .footer-social-list {
  margin-top: 0;
}
.footer .footer-contact-text {
  margin: 0;
  padding: 0.3125rem 0px 0.3125rem 0px;
  line-height: 13.3px;
  height: 18px;
}
.footer #subscribe-text {
  font-size: 0.5rem;
}
.footer .social h2 a {
  color: #f8f8f8;
  font-weight: normal;
}
.footer .input-box {
  margin: 1rem 0 0.8rem 0;
}
.footer .submit-button {
  background-color: #509090;
  color: #f8f8f8;
  display: block;
  width: 100%;
  border: none;
  padding: 0.5em;
  cursor: pointer;
}
@media (max-width: 500px) {
  .footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer .subscribe-field {
    width: 17rem;
  }
  .footer .container > .subscribe {
    order: 1;
  }
  .footer .container > .company {
    order: 2;
    width: 196px;
  }
  .footer .container > .other {
    order: 3;
    width: 196px;
  }
  .footer .container > .social {
    order: 4;
  }
}

.md-36 {
  font-size: 36px;
}

.md-48 {
  font-size: 48px;
}

.link:link {
  color: #157963;
  text-decoration: none;
}
.link:visited {
  color: #157963;
}

.bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 10px 25px 0px #7a7a7a;
  max-width: 100%;
  width: 500px;
  height: 200px;
  margin: 0 5%;
}

.proj-title {
  letter-spacing: 0.8px;
}
.proj-title a:link,
.proj-title a:visited,
.proj-title a:hover {
  text-decoration: none;
  color: white;
}

.split {
  margin: 20px 0;
  border-bottom: solid 1.5px;
  max-width: 50px;
}

.project {
  margin: 1rem;
  padding: 1rem 2rem;
  height: 270px;
  max-width: 70%;
  width: 300px;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 15px 15px -10px #7a7a7a;
}

.bg1:hover,
.bg2:hover,
.bg3:hover,
.bg4:hover {
  text-decoration: none;
}

.splash {
  font-weight: bolder;
  color: #000;
}

.text-stroke {
  text-shadow: -1px -1px 0 #646464, 1px -1px 0 #646464, -1px 1px 0 #646464, 1px 1px 0 #646464;
}

.date {
  color: #f8f8f8;
}
.date:hover, .date:visited {
  text-decoration: none;
}

.tags li {
  cursor: pointer;
  padding: 7px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.tags li a {
  color: white;
}
.tags li a:hover {
  text-decoration: none;
}
.tags li:nth-child(4n-3) {
  background-color: #509090;
}
.tags li:nth-child(4n-3):hover {
  background-color: #7eb3b3;
}
.tags li:nth-child(4n-2) {
  background-color: #40597b;
}
.tags li:nth-child(4n-2):hover {
  background-color: #627895;
}
.tags li:nth-child(4n-1) {
  background-color: #ff533d;
}
.tags li:nth-child(4n-1):hover {
  background-color: #ff7361;
}
.tags li:nth-child(4n-4) {
  background-color: #555555;
}
.tags li:nth-child(4n-4):hover {
  background-color: #7a7a7a;
}

.codetable .linenos {
  padding-right: 0.3rem;
}

pre.code,
code {
  white-space: pre;
  word-wrap: normal;
  overflow: auto;
}

.blog-footer {
  font-size: calc(14px + 2 * (100vw - 300px) / 1300);
  color: #7a7a7a;
  width: 90%;
  max-width: 720px;
  margin: 0 auto;
}
.blog-footer a:link,
.blog-footer a:hover,
.blog-footer a:visited {
  color: #157963;
}

.blog-index .tags {
  display: inline;
}

/* blog widgets */
.dateline {
  margin-bottom: 10px;
}

.tagline {
  margin-bottom: -5px;
}

.tags li {
  display: inline-block;
}

.tag i,
.more i,
.dateline i {
  margin-right: 0.2rem;
}

.pager {
  bottom: 70px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  min-height: 60px;
  color: #7a7a7a;
  display: flex;
  justify-content: space-between;
  font-size: calc(16px + 4 * (100vw - 325px) / 395);
  font-weight: 700;
  align-items: center;
}
.pager .previous,
.pager .next {
  color: #157963;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.pager .previous i,
.pager .next i {
  margin: 0 10px;
}
.pager .current-page,
.pager .ellipsis {
  font-weight: bold;
}

.blog-input-box {
  margin: 1rem 0 0.8rem 0;
}

.blog-submit-button {
  background-color: #509090;
  color: #f8f8f8;
  display: block;
  width: 55%;
  border: none;
  padding: 0.5em;
  cursor: pointer;
}

.blog-subscribe {
  width: 20rem;
  margin: 15px;
}

.homepage-blog-preview {
  width: 20rem;
  margin: 15px;
}

/* Images */
.postpage img,
.postpage object,
.postindex article img,
.postindex article object {
  height: auto;
  max-width: 100%;
}

.waitlist-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.waitlist-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 26rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waitlist-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12rem;
}

.waitlist-input {
  margin-bottom: 1rem;
  padding: 0.5em;
  width: 100%;
}

.waitlist-close {
  color: #aaa;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
}

.waitlist-submit-button {
  background-color: #509090;
  color: #f8f8f8;
  display: block;
  width: 100%;
  border: none;
  padding: 0.5em;
  margin-bottom: 3rem;
  cursor: pointer;
}

.waitlist-close:hover,
.waitlist-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nav {
  position: fixed;
  z-index: 99;
  background: #303030dc;
  top: 0;
  height: 60px;
  width: 100%;
}
.nav:hover {
  background: #303030;
}

.headroom {
  transition: transform 0.25s ease-in-out;
  will-change: transform;
}

.headroom--pinned {
  transform: translateY(0);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.dropdown-icon {
  cursor: pointer;
  float: right;
  border-radius: 10px;
  margin-top: 15px;
  padding: 12px 20px 30px 80px;
}

label .hamburger {
  position: relative;
  width: 30px;
  height: 2px;
  background: #f8f8f8;
  display: block;
  transform-origin: center;
  transition: ease all 0.2s;
}

label .hamburger:after, label .hamburger:before {
  transition: ease all 0.2s;
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

label .hamburger:before {
  top: 10px;
}

label .hamburger:after {
  bottom: 12px;
}

.dropdown-icon input {
  display: none;
}

label input:checked + .menu {
  border-radius: 0;
}

label input:checked + .menu .hamburger {
  transform: rotate(45deg);
}

label input:checked + .menu .hamburger:after {
  transform: rotate(90deg);
  bottom: 2px;
}

label input:checked + .menu .hamburger:before {
  transform: rotate(90deg);
  top: 0;
}

label input:checked + .menu + .dropdown-items {
  display: block;
  transition: 0.25s 0s ease-in-out;
}

.dropdown-items {
  display: none;
  list-style-type: none;
  margin: 0;
  right: 0;
  top: 60px;
  width: 100%;
  font-size: 1.1rem;
  background: #303030;
  background-position: right;
  position: absolute;
  z-index: 1;
  box-shadow: -2px 3px 5px 0px #000;
}

.drop-btn {
  border-top: solid 1px #7a7a7a;
}
.drop-btn a {
  width: 90%;
  color: #f8f8f8;
  text-decoration: none;
  text-transform: capitalize;
}
.drop-btn a:visited {
  color: #f8f8f8;
}
.drop-btn h4 {
  margin: 0;
  padding: 1em 0 1em 10%;
}

.headbar {
  display: flex;
  float: right;
  margin: 20px;
}
.headbar .links {
  padding-right: 20px;
  color: #f8f8f8;
  text-transform: capitalize;
}
.headbar .links:hover {
  color: darkgray;
}

@media (max-width: 799px) {
  .headbar {
    display: none;
  }
}
@media (min-width: 800px) {
  .dropdown-icon {
    display: none;
  }
}
